<template>
  <v-container grid-list-xl v-if="!loading">
    <v-row align="center" justify="center">
      <v-col cols="12" lg="10" xl="8">
        <v-data-table
          v-bind:headers="headers"
          v-bind:items="dishList"
          v-bind:search="search"
        >
          <template v-slot:body="{ items }">
            <tbody>
              <tr v-for="(item, index) in items" :key="`dishlist-${index}`">
                <td>
                  <router-link :to="`/product/edit/${item.chef.id}/${item.id}`">
                    {{ item.name }}
                  </router-link>
                </td>
                <td>
                  <template v-if="dishImage(item)">
                    <img
                      :src="dishImage(item)"
                      class="img-responsive"
                      height="100"
                      width="100"
                    />
                  </template>
                  <template v-else>
                    No Image
                  </template>
                </td>

                <td>{{ item.price | currency }}</td>
                <td>
                  <v-edit-dialog
                    @open="tmp = getAmount(item.amountLeft)"
                    @save="saveMountLeft(item.id, tmp)"
                    large
                    lazy
                  >
                    <div>{{ getAmount(item.amountLeft) }}</div>
                    <div slot="input" class="mt-3 title">Update Amount</div>
                    <v-text-field
                      slot="input"
                      label="Edit"
                      v-model="tmp"
                      single-line
                      autofocus
                      :rules="[positive]"
                    >
                    </v-text-field>
                  </v-edit-dialog>
                </td>
                <td>{{ item.leadTime }}</td>
                <td>{{ item.orders ? item.orders.length : null }}</td>
              </tr>
            </tbody>
          </template>
          <!-- <template slot="pageText" slot-scope="{ pageStart, pageStop }">
            From {{ pageStart }} to {{ pageStop }}
          </template> -->
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import { i18n } from 'fod-core'

export default {
  data() {
    return {
      loader: true,
      positive: v =>
        parseFloat(v) >= 0 || v == 'unlimited' || 'Must be a positive number!',
      tmp: '',
      search: '',
      pagination: {},
      headers: [
        {
          text: 'Name',
          align: 'left',
          sortable: false,
          value: 'name'
        },
        { text: '', sortable: false, value: '' },
        { text: i18n.t('Common.price'), value: 'price' },
        { text: i18n.t('Chef.amount_left'), value: 'amount_left' },

        { text: i18n.t('Chef.lead_time'), value: 'lead_time' },

        { text: i18n.t('Common.active_orders'), value: 'orders' }
      ],
      items: []
    }
  },
  computed: {
    ...mapGetters({
      loading: 'loading',
      dishList: 'dishList'
    })
  },
  mounted() {
    this.$store.dispatch('loadDishList')
  },
  methods: {
    dishImage(dish) {
      return dish && dish.photos && dish.photos.length > 0
        ? dish.photos[0].url || dish.photos[0].imageUrl
        : null
    },
    saveMountLeft(id, amount) {
      if (parseFloat(amount) > 0 || amount == 'unlimited') {
        if (amount == 'unlimited') amount = -1

        this.$store
          .dispatch('updateDishInfo', { id: id, amountLeft: amount })
          .then(() => {
            this.$store.dispatch('loadDishList', { force: true })
          })
      }
    },
    getAmount(amount) {
      return amount == -1 ? 'unlimited' : amount
    }
  }
}
</script>

<style></style>
